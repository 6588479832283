import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"

export default function LawManagement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO
        title="Online Law firm management software | Touchcore Technology Nigeria"
        description="LegalPro is a Cloud-based Legal Practice Management Software that allows you or team to work from anywhere"
      />
      <SolutionLayout
        data={{
          slug: "legal-pro",
          hero: {
            image: require("../assets/legalpro-screen-1.png"),
            titleSecond: "LegalPro",
            subtitle: "Cloud based law office management system.",
            content:
              "LegalPro is an Online Software for Lawyers daily case board management.With this application Lawyers/ Advocates can keep the records at their fingertips..",
          },
          section: {
            title: "How can LegalPro help you?",
            subtitle: (
              <>
                You can add information like client cases, case history and
                appointment on the system and access them form virtually
                anywhere
                <br />
                LegalPro is the digital diary which keeps everything organized
                and easily accessible way.
              </>
            ),
          },
          imageSection1: {
            image: require("../assets/Legalpro-screen.png"),
            title: "Easily manage your clients",
            content:
              "LegalPro has the capability to store client details like Name, Phone, Email, cases against him/ her with case history.",
          },

          imageSection2: {
            image: require("../assets/legalpro-2.png"),
            title: "Seamless case management",
            content:
              "Track all the details like Client name, Case number, judge, Court details, Case history and transfer history.",
          },
        }}
      />
    </Layout>
  )
}
